import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { isMobile } from "react-device-detect";

export const MainDetailSection = () => (
  <div
    style={{ height: isMobile ? "46em" : "28em", backgroundColor: "#ebebeb" }}
  >
    <StepContainer>
      <DetailTitle variant="h5">WHY GREETABLE?</DetailTitle>
    </StepContainer>
    <StepContainer>
      <PointContainer>
        <ion-icon name="mail-open-outline" style={{ fontSize: "3.6em" }} />
        <StepTitle variant="h4">
          Overwhelmed by the number of DMs, messages, and questions from your
          followers or clients? Get paid to answer them at your convenience.
        </StepTitle>
      </PointContainer>
      <PointContainer>
        <ion-icon name="repeat-outline" style={{ fontSize: "3.6em" }} />
        <StepTitle variant="h4">
          Make it easier and cheaper for people to interact with you. Charging
          $5 for each question that takes you a minute to answer means you’re
          making $300/hr.
        </StepTitle>
      </PointContainer>
      <PointContainer>
        <ion-icon name="timer-outline" style={{ fontSize: "3.6em" }} />
        <StepTitle variant="h4">
          Post the questions you’ve previously answered on a feed and create
          engagement, or better yet, charge for access to previous answers and
          create passive income for yourself.
        </StepTitle>
      </PointContainer>
    </StepContainer>
  </div>
);

const StepContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const PointContainer = styled.div`
  justify-content: ${isMobile ? "center" : "flex-start"};
  width: ${isMobile ? "85%" : "24em"};
  padding: ${isMobile ? "1em 2em 1em 2em" : "2em"};
`;

const StepTitle = styled(Typography)`
  display: flex;
  margin: 0.5em 0em 0.5em 0em;
  margin-bottom: ${isMobile ? "0.5em" : "0.3em"};
`;

const DetailTitle = styled(Typography)`
  margin-top: 2em;
  margin-bottom: 1em;
  text-align: center;
`;
