import React from "react";
import { Button } from "@material-ui/core";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

export type ButtonType = {
  text: string;
  color: any;
  variant: any;
  backgroundColor: string;
  onClick?: any;
  height?: string;
  fontSize?: string;
  type?: any;
};

export const SubmitButton: React.SFC<ButtonType> = ({
  text,
  color,
  variant,
  backgroundColor,
  onClick,
  type
}) => {
  return (
    <CustomButton
      size={isMobile ? "small" : "large"}
      color={color}
      variant={variant}
      type={type}
      style={{
        borderRadius: 8,
        backgroundColor,
        borderColor: "black"
      }}
      onClick={onClick}
    >
      {text}
    </CustomButton>
  );
};

const CustomButton = styled(Button)`
  text-transform: none !important;
  font-size: 0.9rem !important;
  font-weight: 600 !important;
  height: 3.8em;
  width: "100% !important";
  margin-bottom: ${isMobile ? "0.8em !important" : "0em !important"};
`;
