import React from "react";

export const GradientBackground = ({ height, backgroundImage, children }) => (
  <div
    style={{
      backgroundImage,
      height,
      position: "relative"
    }}
  >
    {children}
  </div>
);
