import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.css";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { muiTheme } from "./theme";
import { UserContext } from "./contexts/UserContext";
import { Home } from "./containers/Home/Home";

function App() {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: ""
  });

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
          </Switch>
        </Router>
      </ThemeProvider>
    </UserContext.Provider>
  );
}

export default App;

const theme = createMuiTheme(muiTheme);
theme.typography.h1 = {
  fontSize: "2.6rem",
  fontWeight: 800,
  // "@media (min-width:600px)": {
  //   fontSize: "1.4rem"
  // },
  [theme.breakpoints.up("md")]: {
    fontSize: "4rem",
    fontWeight: 800
  }
};

theme.typography.h2 = {
  fontSize: "1rem",
  fontWeight: 400,
  [theme.breakpoints.up("md")]: {
    fontSize: "1.6rem",
    fontWeight: 400
  }
};

theme.typography.h3 = {
  fontSize: "1.3rem",
  fontWeight: 600,
  [theme.breakpoints.up("md")]: {
    fontSize: "1.3rem",
    fontWeight: 700
  }
};

theme.typography.h4 = {
  fontWeight: 400,
  fontSize: "0.95rem",
  "@media (min-width:600px)": {
    fontSize: "0.4rem",
    fontWeight: 300
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
    fontWeight: 400
  }
};

theme.typography.h5 = {
  fontSize: "1.3rem",
  fontWeight: 800,
  [theme.breakpoints.up("md")]: {
    fontSize: "1.6rem",
    fontWeight: 700
  }
};

theme.typography.h6 = {
  fontSize: "1.2rem",
  "@media (min-width:600px)": {
    fontSize: "1.2rem"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.8rem",
    fontWeight: 700
  }
};

theme.typography.body1 = {
  fontSize: "0.9rem",
  "@media (min-width:600px)": {
    fontSize: "0.9rem"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "0.9rem",
    fontWeight: 600
  }
};

theme.typography.body2 = {
  fontSize: "1.2rem",
  "@media (min-width:600px)": {
    fontSize: "1rem"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.2rem",
    fontWeight: 600
  }
};

theme.typography.subtitle1 = {
  fontSize: "1.2rem",
  "@media (min-width:600px)": {
    fontSize: "1rem"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.4rem",
    fontWeight: 700
  }
};

theme.typography.subtitle2 = {
  fontSize: "1rem",
  fontWeight: 600,
  "@media (min-width:600px)": {
    fontSize: "1rem"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.1rem",
    fontWeight: 600
  }
};

theme.typography.subtitle3 = {
  fontSize: "0.6rem",
  fontWeight: 600,
  "@media (min-width:600px)": {
    fontSize: "0.9rem"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
    fontWeight: 600
  }
};
