import React, { useState } from "react";

import { Header } from "../../../ui/Header";
import styled from "styled-components";
import { Typography, FormHelperText } from "@material-ui/core";

import { isMobile } from "react-device-detect";
import { SubmitButton } from "../../../ui/SubmitButton";

import { InputField } from "../../../ui/InputField";
import { Formik, Form } from "formik";

async function callingFn(email) {
  try {
    const response = await fetch(
      "https://v1.nocodeapi.com/akang/google_sheets/IzHvokTVNgcuNIsS?tabId=Sheet1",
      {
        method: "post",
        body: JSON.stringify([[email]]),
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    const json = await response.json();
    console.log("Success:", JSON.stringify(json));
  } catch (error) {
    console.error("Error:", error);
  }
}

export const CopySection = () => {
  const [submitted, setSubmitted] = useState(false);
  return (
    <div style={{ height: "46em", zIndex: 1 }}>
      <Header logoText={"greetable"} />
      <Formik
        initialValues={{
          email: ""
        }}
        onSubmit={async (values, { resetForm }) => {
          try {
            await callingFn(values.email);
            await setSubmitted(true);
            window.gtag("event", "sign_up", {
              method: "email"
            });
            resetForm();
          } catch (e) {
            console.log(e);
          }
        }}
      >
        {({ values, handleChange, handleBlur, isSubmitting }) => (
          <Form>
            <CopyContainer>
              <div
                style={{
                  width: `${isMobile ? "100%" : "55%"}`,
                  alignSelf: "center"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignSelf: "center"
                  }}
                >
                  <CopyText variant="h1">
                    The easiest way to get paid for your time
                  </CopyText>
                  <CopyText variant="h2">
                    Greetable lets you charge your followers and clients for
                    Q&A. Charge per question. Create engagement by showing all
                    the previous questions you’ve answered on your personal
                    feed.
                  </CopyText>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: `${isMobile && "column"}`,
                    justifyContent: "flex-start",
                    alignSelf: "center"
                  }}
                >
                  <EmailContainer>
                    <InputField
                      label="Enter your email address"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      value={values.email}
                    />
                    {submitted && (
                      <FormHelperText margin="dense">
                        Thanks for submitting!
                      </FormHelperText>
                    )}
                  </EmailContainer>
                  <ButtonContainer>
                    {!isMobile && (
                      <SubmitButton
                        text="Join the Waitlist"
                        color="primary"
                        variant="contained"
                        backgroundColor={"black"}
                        type="submit"
                        disabled={isSubmitting}
                      />
                    )}
                  </ButtonContainer>
                </div>
              </div>
              {isMobile && (
                <SubmitButton
                  text="Join the Waitlist"
                  color="primary"
                  variant="contained"
                  backgroundColor={"black"}
                  type="submit"
                  disabled={isSubmitting}
                />
              )}
              <div
                style={{
                  width: `${isMobile ? "100%" : "55%"}`,
                  alignSelf: "center"
                }}
              >
                <CopyText
                  variant="body1"
                  style={{
                    marginTop: isMobile ? "1em" : "2em",
                    display: "flex",
                    justifyContent: "flex-start",
                    fontWeight: "bold"
                  }}
                >
                  Sign-up now and we’ll give you exclusive access to our private
                  beta when it’s released.
                </CopyText>
              </div>
            </CopyContainer>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.7em;
  padding-top: ${isMobile ? "0.5em" : "4em"};
`;

const CopyText = styled(Typography)`
  display: flex;
  justify-content: center;
  margin-bottom: ${isMobile ? "0.5em" : "0.3em"};
`;

const EmailContainer = styled.div`
  flex-direction: row;
  width: ${isMobile ? "100%" : "30em"};
  justify-content: center;
  margin-top: ${isMobile ? "1em" : "2em"};
`;

const ButtonContainer = styled.div`
  flex-direction: row;
  padding-left: 0.8em;
  justify-content: center;
  margin-top: ${isMobile ? "1em" : "2em"};
`;
