import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { Typography } from "@material-ui/core";

export const Footer = () => (
  <div
    style={{
      height: "20em",
      display: "flex",
      justifyContent: `${isMobile ? "flex-start" : "flex-end"}`
    }}
  >
    <StepContainer>
      <Step>
        <StepTitle variant="subtitle2">Contact</StepTitle>
      </Step>
      {/* <Step>
        <TitleContainer>
          <ion-icon
            name="location-sharp"
            style={{ margin: "0.1em 0em 0em 0.4em" }}
          />
          <StepTitle variant="subtitle2">Address</StepTitle>
        </TitleContainer>
        <StepTitle variant="subtitle3">838 West Hastings St</StepTitle>
        <StepTitle variant="subtitle3">Vancouver, BC, V6C0A6</StepTitle>
      </Step> */}
      <Step>
        <TitleContainer>
          <ion-icon
            name="mail-sharp"
            style={{ margin: "0.2em 0em 0em 0.4em" }}
          />
          <StepTitle variant="subtitle2">Email</StepTitle>
        </TitleContainer>
        <StepTitle variant="subtitle3">greetableapp@gmail.com</StepTitle>
      </Step>
    </StepContainer>
  </div>
);

const StepContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const Step = styled.div`
  justify-content: flex-start;
  width: ${isMobile ? "18em" : "16em"};
  padding: 1.8em;
  margin-top: ${isMobile ? "-3em" : "-3em"};
`;

const StepTitle = styled(Typography)`
  display: flex;
  margin-left: 0.5em;
  margin-bottom: ${isMobile ? "0.5em" : "0.3em"};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
