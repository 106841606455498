export const muiTheme = {
  palette: {
    primary: {
      light: "#FFFFFF",
      main: "#FFFFFF",
      dark: "#FFFFFF",
      contrastText: "#FFFFFF"
    },
    secondary: {
      light: "#000000",
      main: "#000000",
      dark: "#000000",
      contrastText: "#000000"
    }
  },
  shadows: ["none"]
};
