import React from "react";

import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { isMobile } from "react-device-detect";

export const DetailSection = () => (
  <div style={{ height: isMobile ? "55em" : "38em" }}>
    <StepContainer>
      <DetailTitle variant="h5">GET STARTED</DetailTitle>
    </StepContainer>
    <StepContainer>
      <Step>
        <ion-icon
          name="people-sharp"
          style={{ color: "#12c2e9", fontSize: "3.6em" }}
        />
        <StepTitle variant="h3">Sign up to join the waitlist</StepTitle>
        <StepTitle variant="h4">
          Get notified when Greetable is released!
        </StepTitle>
      </Step>
      <Step>
        <ion-icon
          name="pricetags-sharp"
          style={{ color: "#c471ed", fontSize: "3.6em" }}
        />
        <StepTitle variant="h3">Set the price</StepTitle>
        <StepTitle variant="h4">
          Charge per question asked and pick your price. We’ll send your
          followers your responses by email.
        </StepTitle>
      </Step>
    </StepContainer>
    <StepContainer>
      <Step>
        <ion-icon
          name="link-sharp"
          style={{ color: "#f64f59", fontSize: "3.6em" }}
        />
        <StepTitle variant="h3">Add your unique link to your bio</StepTitle>
        <StepTitle variant="h4">
          We’ll send this to you as soon as we launch.
        </StepTitle>
      </Step>
      <Step>
        <ion-icon
          name="cash-sharp"
          style={{ color: "#85bb65", fontSize: "3.6em" }}
        />
        <StepTitle variant="h3">Get paid</StepTitle>
        <StepTitle variant="h4">
          All you have to do is show up and answer questions on your own
          schedule. We take a 10% cut for handling payments and running the
          service.
        </StepTitle>
      </Step>
    </StepContainer>
  </div>
);

const StepContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: ${isMobile ? "column" : "row"};
`;

const Step = styled.div`
  justify-content: ${isMobile ? "center" : "flex-start"};
  width: ${isMobile ? "85%" : "24em"};
  padding: ${isMobile ? "1em 2em 1em 2em" : "2em"};
`;

const StepTitle = styled(Typography)`
  display: flex;
  margin: 0.5em 0em 0.5em 0em;
  margin-bottom: ${isMobile ? "0.5em" : "0.3em"};
`;

const DetailTitle = styled(Typography)`
  margin-top: 2em;
  margin-bottom: 1em;
  text-align: center;
`;
