import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Toolbar, Typography, Button, AppBar } from "@material-ui/core";

export const Header = ({
  logoText,
  buttonText,
  gradient,
  transparent,
  onClick
}) => {
  const history = useHistory();

  return (
    <AppBar
      position="static"
      style={{
        background: "transparent",
        boxShadow: "none"
      }}
    >
      <HeaderBar
        style={
          gradient
            ? {
                "background-image":
                  "linear-gradient(to bottom right, #12c2e9, #c471ed, #f64f59)"
              }
            : null
        }
      >
        <Typography
          variant="subtitle1"
          style={transparent ? null : { color: "black" }}
          onClick={() => !localStorage.user && history.push("/")}
        >
          {logoText}
        </Typography>

        <Button
          color="inherit"
          style={transparent ? null : { color: "black" }}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </HeaderBar>
    </AppBar>
  );
};

const HeaderBar = styled(Toolbar)`
  min-height: 128;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 2em;
  padding-bottom: 2em;
`;
