import React from "react";
import { useHistory } from "react-router-dom";
import { CopySection } from "./components/CopySection";
import { GradientBackground } from "../../ui/GradientBackground";
import { DetailSection } from "./components/DetailSection";
import { Footer } from "./components/Footer";
import { MainDetailSection } from "./components/MainDetailSection";

// images

// formatting

export const Home = () => {
  const history = useHistory();

  return (
    <GradientBackground
      height="70em"
      backgroundImage={
        "linear-gradient(to top right, #ffffff 30%, #ffffff, #12c2e9, #c471ed, #f64f59)"
      }
    >
      <CopySection />
      <MainDetailSection />
      <DetailSection />
      <Footer />
    </GradientBackground>
  );
};
